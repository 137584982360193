import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import loading from '../img/loading.json'

import "./join.css"

const Dashboard = () => {
  return (
    <>
      <div className='loading'>
        <Lottie animationData={loading} loop={true}  style={{width:"30%",margin:"auto"}}/>
      </div>
    </>
  )
}

export default Dashboard