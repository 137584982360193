import React, { useState,useEffect } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useParams} from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./join.css"
import Lottie from "lottie-react";
import loading from '../img/loading.json'
import { Invalid } from './Invalid';
import queryString from 'query-string'


ZoomMtg.setZoomJSLib('https://source.zoom.us/2.8.0/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');
function Join_meeting() {
    const [cookies, setCookie] = useCookies(['user']);
    const [event,setEvent]=useState([]);
    const [eventParams,setEventParams]=useState([]);
    const [invalid,setInvalid]=useState(false);
    const [isAuthenticate,setIsauthenticate]=useState(false);
    const navigate=useNavigate();
    const params=useParams();
    
    useEffect(() => {
      const id=(params['id']);
      const queryParams = new URLSearchParams(window.location.search);
      const st_id = queryParams.get('sid');
      const s_key = queryParams.get('sk');
      let type = queryParams.get('type');
      if(type>0){

      }else{
        type = '';
      }
      setEventParams({id,st_id,s_key,type})      
      document.title='GSSCORE';
    },[isAuthenticate])
    
    useEffect(() => {
      get_event_details();
    }, [eventParams])  
    
    useEffect(() => {      
      document.title="Mentorship Session";
      console.log(event);
      if(event.valid_user){
        getSignature(); 
      }
    },[event])

    const get_event_details=()=>{
      console.log(eventParams);
      const api_params={
        'schedule_id':eventParams.id,
        'student_id':eventParams.st_id,
        'student_key':eventParams.s_key,
        'schedule_type':eventParams.type,
      };
      
      const live_url="https://gsscore.com/api/abl/get_schedule_details";
      axios.post(live_url, api_params,{
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
        }
      })
      .then((response) => {  
        // console.log(response.data.details);    
        if(response.data.details){
          setEvent(response.data.details)
        }else{
          setInvalid(1);
        }   
      }, (error) => {
        console.log(error);        
      });
    }

    const schedule_joined_date_time_insert=()=>{
      const live_url="https://gsscore.com/api/abl/schedule_joined_data_insert";
      const api_params={
        "schedule_id": eventParams.id,
        "user_id": eventParams.st_id,
        "user_type": 4,
      };
      axios.post(live_url, api_params,{
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
        }
      })
      .then((response) => {  
        console.log(response.data.details);    
         
      }, (error) => {
        console.log(error);        
      });
    }

    
    // var signatureEndpoint = 'https://conapi.mentorconnect.leco.live'
    var signatureEndpoint = 'https://conapi.iasscore.in'

    var sdkKey = '88Itp1a0NGJ7VTaVW3ZYpX6VLbCwhAdyD9Di';
    var meetingNumber = event.meeting_id;
    var role = 0
    var leaveUrl = 'https://gsscore.com/thank-you'
    var userName = event.name+" "+event.last_name
    var userEmail = event.email
    if(eventParams.type==2){
      var passWord =  event.added_by
    }else{
      var passWord =  event.user_id
    }
    var registrantToken = ''
  function getSignature() {    
    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'
    // console.warn('signature',signature);
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success)
            schedule_joined_date_time_insert();
            var left_side_container = document.getElementsByClassName('meeting-info-container meeting-info-container--left-side')
            for (var i = 0; i < left_side_container.length; i++){
              left_side_container[i].style.display = 'none';
            }
          },
          error: (error) => {
            // console.log('click on join btn ')
            console.log(error)
          }
        })
      },
      error: (error) => {
        console.log(error)
        // console.log('click on join btn ')

      }
    })
  }

  return (
    <div className="App">
      {
      invalid ? 
        <Invalid/>        
        :        
        <div className='loading'>
          {/* <Lottie animationData={loading} loop={true}  style={{width:"30%",margin:"auto"}}/> */}
        </div>
      }
    </div>
        
  );
}

export default Join_meeting;
