import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

const AdminContext=createContext();

const Context=({children})=>{ 

    const [is_loggedIn, setIs_loggedIn] = useState(false);
    const [cookies, setCookie] = useCookies(['user']);
    useEffect(() => {
        if(cookies.logged_in>1 || cookies.userId>1){
           setIs_loggedIn(true);
        }else{
            setIs_loggedIn(false);
        }
    }, [])    
    
    return  <AdminContext.Provider value={{is_loggedIn,setIs_loggedIn}}>
                {children}
            </AdminContext.Provider>;
};
export const AdminState=()=>{
    return useContext(AdminContext)
}
export default Context;