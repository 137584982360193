import React, { useEffect, useState } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Lottie from "lottie-react";
import loading from '../img/loading.json'
import { Invalid } from './Invalid';
import "./join.css"

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.8.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function Start_meeting() {
    const [event,setEvent]=useState([]);
    const [eventParams,setEventParams]=useState([]);
    const [invalid,setInvalid]=useState(false);
    const [isAuthenticate,setIsauthenticate]=useState(false);
    const navigate=useNavigate();
    const params=useParams();
    
    useEffect(() => {
      const id=(params['id']);
      //http://localhost:3000/start/361?mid=654&mk=ad9262a86085fcd1a640b5ddba1d2f57
      const queryParams = new URLSearchParams(window.location.search);
      const m_id = queryParams.get('mid');
      const m_key = queryParams.get('mk');
      const type = queryParams.get('type');
      setEventParams({id,m_id,m_key,type})      
      document.title='GSSCORE';
    },[isAuthenticate])
    
    useEffect(() => {
      get_event_details();
    }, [eventParams])  
    
    useEffect(() => {      
      document.title="Mentorship Session";
      console.log(event);
      if(event.valid_user){
        getSignature(); 
      }

    },[event])

    const get_event_details=()=>{
      // console.log(eventParams);
      const api_params={
        'schedule_id':eventParams.id,
        'student_id':eventParams.m_id,
        'student_key':eventParams.m_key,
        'schedule_type':eventParams.type,
      };
      const live_url="https://gsscore.com/api/abl/get_schedule_details_for_mentor";
      // const live_url="https://api.leco.live/abl/get_schedule_details_for_mentor";
      axios.post(live_url, api_params,{
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
        }
      })
      .then((response) => {  
        // console.log(response.data.details);    
        if(response.data.details){
          setEvent(response.data.details)
        }else{
          setInvalid(1);
        }   
      }, (error) => {
        console.log(error);        
      });
    }

    const schedule_joined_date_time_insert=()=>{
      const live_url="https://gsscore.com/api/abl/schedule_joined_data_insert";
      const api_params={
        "schedule_id": eventParams.id,
        "user_id": eventParams.m_id,
        "user_type": 3,
      };

      axios.post(live_url, api_params,{
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
        }
      })
      .then((response) => {  
        console.log(response.data.details);    
         
      }, (error) => {
        console.log(error);        
      });
    }
    
  var signatureEndpoint = 'https://conapi.iasscore.in'
  var sdkKey = '88Itp1a0NGJ7VTaVW3ZYpX6VLbCwhAdyD9Di';
  var meetingNumber = event.meeting_id;
  var role = 1
  var leaveUrl = 'https://gsscore.com/thank-you'
  var userName = event.name+" "+event.last_name
  var userEmail = event.email
  if(eventParams.type==2){
    var passWord =  event.added_by
  }else{
    var passWord =  event.booked_by
  }
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  var registrantToken = ''

  function getSignature() {
    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'
    // document.getElementById('join-btn').innerHTML = 'Start Session'
    // document.getElementById('join-btn').style.display= 'none'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      disableInvite: true,
      success: (success) => {
        console.log(success)
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            // console.log(success) 
            schedule_joined_date_time_insert(); 
            var left_side_container = document.getElementsByClassName('meeting-info-container meeting-info-container--left-side')
            for (var i = 0; i < left_side_container.length; i++){
              left_side_container[i].style.display = 'none';
            }          
          },
          error: (error) => {
            console.log(error)
          }
        })
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      {invalid ? 
       <Invalid/>        
      :
      <div className='loading'>
        {/* <Lottie animationData={loading} loop={true}  style={{width:"30%",margin:"auto"}}/> */}
      </div>
      }
    </div>
  );
}

export default Start_meeting;
