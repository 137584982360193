import axios from 'axios';
import React from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';

const Unauthorised = () => {
  const Img_style={
    textAlign:"center",
    margin:"auto",
    width:"250px",
  };

  const login=(e)=>{
    e.preventDefault();
    const url='https://iasscore.in/api/auth/site_login';
    const form_data={
      email:"asifansari@basix.in",
      password:"score@987"
    };
    console.log(form_data)
   
    axios.post(url,form_data,{
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
      }
    })
    .then((response)=>{
      console.log(response);
    },(error)=>{
      console.log(error);
    });
  }
  return (
    <>
    <div className='row'>
      <Col md={4}>
      </Col>
      <Col md={4}>
        <div className='card p-4 mt-3'>
          <img src="https://iasscore.in/assets/img/iasscore-logo.png" alt="" className='img-fluid' style={Img_style}  />
          <Form onSubmit={login}>
            <h3 className='text-center text-danger'>Login :</h3>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <div className='text-center'>
              <Button variant="primary" type="submit">
                Login
              </Button>
            </div>
          </Form>
        </div>        
      </Col>
    </div>
      
    </>
  )
}

export default Unauthorised