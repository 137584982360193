import React from 'react';
import './App.css';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Join_meeting from './component/Join_meeting';
import Start_meeting from './component/Start_meeting';
import Dashboard from './component/Dashboard';
import Unauthorised from './component/Unauthorised';
import Testing from './component/Testing';
import Access_denied from './component/Access_denied';
import ThankU from './component/ThankU';
import OpenSession from './component/OpenSession/OpenSession';
import { Invalid } from './component/Invalid';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Dashboard/>}/>
          <Route path="/join/:id" element={<Join_meeting/>}/>
          <Route path="/start/:id" element={<Start_meeting/>}/>
          <Route path="/unauthorised" element={<Unauthorised/>}/>
          <Route path="/access-denied" element={<Access_denied/>}/>
          <Route path="/thank-you" element={<ThankU/>}/>
          <Route path="/invalid" element={<Invalid/>}/>         
          <Route path="*" element={<h5>404 page not found</h5>}/>
        </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
